// import PropTypes from "prop-types";
import React from "react"
import Page from "../components/Page"

const Projects = () => {
  return (
    <Page>
      <h2>Projects</h2>
    </Page>
  )
}

Projects.propTypes = {
  // : PropTypes.string
}

export default Projects
